import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { SmileyQuestion } from '../../../models/smiley-question.model';
import { environment } from '../../../../environments/environment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-smiley-survey-question',
  templateUrl: './smiley-survey-question.component.html',
  styleUrls: ['./smiley-survey-question.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmileySurveyQuestionComponent),
      multi: true
    }
  ]
})
export class SmileySurveyQuestionComponent implements OnInit, ControlValueAccessor {
  lang = environment.lang;
  @Input() question: SmileyQuestion = {
    questionNumber: 0, 
    questionDescription:{
      'el': 'ΕΡΩΤΗΣΗ'
    }, 
    answers:{
        'el':{
          POOR: 'ΠΟΛΥ ΚΑΚΗ',
          NOT_GOOD: 'ΚΑΚΗ',
          OK: 'ΜΕΤΡΙΑ',
          GOOD: 'ΚΑΛΗ',
          EXCELLENT: 'ΕΞΕΡΑΙΤΙΚΗ'
        }
    }
  };
  answer = '';

  disabled = false;
  value = 0;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  onAnswer(answer: string): void {
    this.answer = answer;
    this.value = this.getAnswerNumber(this.answer);
    this.onChange(this.value);
  }

  getAnswerLiteral(answer: string): string {
    switch(answer){
      case 'POOR': return this.question.answers[this.lang].POOR;
      case 'NOT_GOOD': return this.question.answers[this.lang].NOT_GOOD;
      case 'OK': return this.question.answers[this.lang].OK;
      case 'GOOD': return this.question.answers[this.lang].GOOD;
      case 'EXCELLENT': return this.question.answers[this.lang].EXCELLENT;
      default: return '-';
    }
  }

  getAnswerNumber(answer:string): number {
    switch(answer){
      case 'POOR': return 1;
      case 'NOT_GOOD': return 2;
      case 'OK': return 3;
      case 'GOOD': return 4;
      case 'EXCELLENT': return 5;
      default: return 0;
    }
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}