<br>
<div fxLayout="row" fxLayoutAlign="center center">
  <img src="./assets/images/logos/all_for_you.png" class="main-logo">
</div>
<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="15px">
  <img src="./assets/images/logos/zakcret_sports.png" class="store-logo">
  <img src="./assets/images/logos/sneaker_cage.png" class="store-logo">
  <img src="./assets/images/logos/outlet_center.png" class="store-logo">
</div>
<br>

<router-outlet></router-outlet>