<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" style="height: 60vh;">
     <!-- Very happy smiley face -->
     <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" height="20%" fill="var(--primary-font-color)" >
        <g>
            <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480    C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z"/>
            <circle cx="176" cy="176" r="32"/>
            <circle cx="336" cy="176" r="32"/>
            <path d="M368,256c0,61.856-50.144,112-112,112s-112-50.144-112-112h-32c0,79.529,64.471,144,144,144s144-64.471,144-144H368z"/>
        </g>
    </svg>
    <h2>Σε ευχαριστούμε!</h2>
    <p class="centered">Ελπίζουμε να σε δούμε ξανά σε ένα από τα καταστήματα μας!</p>
</div>