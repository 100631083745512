<div *ngIf="tokenIsValid" class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="35px">
    <h2>Η ανταπόκρισή σου είναι σημαντική για εμάς</h2>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <h3>Αγαπητό μέλος</h3>
        <p class="start-page-description">
            Απόλαυσες την τελευταία σου επίσκεψη στο κατάστημά μας?<br>
            Θα θέλαμε να έχουμε την άποψη σου, έτσι ώστε να γίνουμε ακόμα καλύτεροι την επόμενη φορά.<br>
            Επιθυμούμε 1’ από το χρόνο σου για να απαντήσεις σε 4 απλές ερωτήσεις!<br>
        </p>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <button id="letsStartBtn" type="button" (click)="onLetsStart()">ΑΣ ΞΕΚΙΝΗΣΟΥΜΕ</button>
    </div>
    <p>Σε ευχαριστούμε για τον χρόνο σου!</p>
    <p class="start-page-description">Το ερωτηματολόγιο θα είναι διαθέσιμο για τις επόμενες 14 ημέρες.</p>
</div>